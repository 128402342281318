import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <p>Vous souhaitez en savoir plus ? Alors, contactez-nous !</p>
      <a href="mailto:info@mathotop.ch">info@mathotop.ch</a>
    </footer>
  );
};

export default Footer;
